import Swiper from 'swiper/bundle';
import 'swiper/css';

const swiper = new Swiper(".voiceSwiper", {
    slidesPerView: 'auto',
    spaceBetween: 30,
    freeMode: true,
    scrollContainer: true,
    grabCursor: true,
    loop: true,
    speed: 4000,
    autoplay: {
        delay: 0,
        disableOnInteraction: false,
    },
});